import React, { useContext, useState } from "react";
import {
	cn,
	f2u,
	useUniqueId,
	DataObserveKey,
	VisualComponent,
	Divider,
	Icon,
	InlineText,
	Tooltip,
	SrOnly,
	Link,
} from "@siteimprove/fancylib";
import { BaseInteractiveList } from "../../actions-and-controls/base-interactive-list/base-interactive-list";
import { DropdownSectionHeader } from "../../actions-and-controls/dropdown-section-header/dropdown-section-header";
import { assignKnobName } from "../../../utils/documentation-utils";
import { useSearchableList } from "../../../utils/hooks";
import { useLabTranslations } from "../../../translations/translations";
import { useDesignToken } from "../../context/theme/theme";
import { InputField } from "../../forms-and-inputs/input-field/input-field";
import { TextHighlight } from "../../text/text-highlight/text-highlight";
import { PageChanger, PageChangerProps } from "../../navigation/pagination/page-changer";
import * as scss from "./action-list.scss";

export type ActionListProps = ActionListValues &
	ActionListContextValues &
	DataObserveKey &
	VisualComponent;

export type ActionListValues = {
	/** Label to describe the list */
	"aria-label"?: string;
	/** alternative to providing an aria-label */
	"aria-labelledby"?: string;
	/** List of strings or objects to display in the list */
	items: ActionListItem[];
	/** Custom render function for items in list */
	itemRenderer?: (renderProps: ItemRendererProps<ListOptionItem>) => JSX.Element;
	/** Enables item search functionality */
	searchable?: boolean;
	/** Whether to close the popover or accordion on item select */
	closeOnSelect?: boolean;
	/** If list needs to be paginated */
	pageChanger?: Omit<PageChangerProps, "total" | "page" | "setPage">;
};

export type ListOptionItem = ActionItem | LinkItem;
export type ActionListItem = ListOptionItem | Divider | SectionHeader;

interface SharedProps {
	text: string;
	description?: string;
	icon?: JSX.Element;
	iconFill?: string;
	selected?: boolean;
	disabled?: boolean;
	tooltip?: string;
}

export type ActionItem = { onClick: () => void } & SharedProps & DataObserveKey;
export type LinkItem = { href: string; openNew?: boolean } & SharedProps & DataObserveKey;

type Divider = { isDivider: true };

type SectionHeader = Omit<SharedProps, "disabled"> & { isSectionHeader: true }; // You can't really "disable" a section header

type ActionListContextValues = {
	id?: string;
	listClassName?: string;
	role?: string;
	optionRole?: string;
	/**
	 * Should be assigned to the first focusable element in the popover.
	 *  This element will receive focus when the dropdown opens
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	firstFocusableRef?: React.RefObject<any>;
	openState?: { isOpen: boolean; setIsOpen: (isOpen: boolean) => void; close: () => void };
};

export const ActionListContext = React.createContext(null as ActionListContextValues | null);

function useUnwrapNullCtx<T>(ctx: React.Context<T | null>): T {
	const c = useContext(ctx);
	if (c === null) {
		return {} as T;
	}
	return c;
}

export const useActionListContext = (): ActionListContextValues =>
	useUnwrapNullCtx(ActionListContext);

function navigateTo(url: string, options: { openNew?: boolean }) {
	const { openNew = false } = options;

	if (openNew) {
		window.open(url, "_blank", "noopener");
	} else {
		location.href = url;
	}
}

function InternalActionList(props: ActionListProps) {
	const {
		id: _id,
		listClassName: _listClassName,
		role: _role,
		optionRole: _optionRole,
		firstFocusableRef: _firstFocusableRef,
		openState: _openState,
	} = useActionListContext();

	const {
		["aria-label"]: ariaLabel,
		items: allItems,
		itemRenderer,
		searchable,
		closeOnSelect,
		pageChanger,
		id = _id,
		listClassName = _listClassName,
		role = _role ?? "list",
		optionRole = _optionRole ?? "listitem",
		firstFocusableRef = _firstFocusableRef,
		openState = _openState,
		...rest
	} = props;

	const [query, setQuery] = React.useState("");
	const items = useSearchableList<ActionListItem & { index: number }>(
		allItems.map((i, idx) => ({ ...i, index: idx })),
		query,
		stringifyItem
	);
	const i18nLab = useLabTranslations();

	if (isItemDivider(items[0]) || isItemDivider(items[items.length - 1])) {
		throw new Error("First and last item in an ActionList cannot be a Divider");
	}

	const skipListId = useUniqueId("skipList");

	const [page, setPage] = useState(1);
	const paginatedItems = !pageChanger
		? items
		: items.slice((page - 1) * pageChanger?.pageSize, page * pageChanger?.pageSize);

	const [selected, setSelected] = useState(
		items.find((item) => "selected" in item && item.selected)?.index ?? -1
	);

	const shownItems = paginatedItems.map((item) => ({
		...item,
		selected: "index" in item && item.index === selected,
	}));

	const noIcons = shownItems.filter((item) => !("isDivider" in item) && item.icon).length === 0;

	// Add dividers before headers. We do this, so the dividers are in their own <li> elements,
	// which semantically makes more sense and makes padding automatically consistent.
	// 	`i` starts at `1`, so we don't add a divider above a header if it's the very first item.
	for (let i = 1; i < items.length; i++) {
		if ("isSectionHeader" in items[i]) {
			items.splice(i, 0, { isDivider: true, index: items[i].index }); // <-- insert divider at index
			i++; // <-- everything shifted, so we skip `i` forward to catch up.
		}
	}

	const { ColorTextInteractiveDarkDefault } = useDesignToken();

	return (
		<div {...rest}>
			{searchable && (
				<InputField
					aria-autocomplete="list"
					aria-controls={id}
					aria-expanded={true}
					aria-label={i18nLab.search}
					autoFocus
					className={cn(scss.searchInputField)}
					onChange={setQuery}
					placeholder={i18nLab.search}
					type="search"
					ref={firstFocusableRef}
					value={query}
				/>
			)}
			<SrOnly>
				<Link href={`#${skipListId}`}>{i18nLab.skipList}</Link>
			</SrOnly>
			<BaseInteractiveList
				listId={id}
				listRef={searchable ? undefined : firstFocusableRef}
				listClassName={listClassName}
				liClassName={(item) => {
					if ("isDivider" in item) return scss.divider;
				}}
				items={shownItems}
				itemRenderer={(item) =>
					actionItemRenderer(
						{ item, noIcons, needle: query, iconFill: ColorTextInteractiveDarkDefault },
						itemRenderer
					)
				}
				role={role}
				optionRole={optionRole}
				aria-label={ariaLabel}
				onItemSelect={(item) => {
					if (!isListOptionItem(item) || item.disabled) return;
					setSelected(item.index);
					if ("onClick" in item) {
						item.onClick();
					} else if ("href" in item) {
						navigateTo(item.href, { openNew: item.openNew });
					}
					closeOnSelect && openState && openState.setIsOpen(false);
				}}
				isNotAnOption={(item) => !isListOptionItem(item)}
				isDisabled={(item) => "disabled" in item && !!item.disabled}
				stringify={stringifyItem}
			/>
			{pageChanger && !(page == 1 && pageChanger.pageSize >= items.length) && (
				<div role="group" aria-label="{i18nLab.listPaginationLabel}">
					<PageChanger
						className={scss.pagination}
						total={items.length}
						page={page}
						setPage={setPage}
						{...pageChanger}
					/>
				</div>
			)}
			<div id={skipListId} />
		</div>
	);
}

export type ItemRendererProps<T> = {
	item: T;
	noIcons: boolean;
	needle: string;
	iconFill: string;
};

const actionItemRenderer = (
	renderProps: ItemRendererProps<ActionListItem>,
	customRenderer?: (renderProps: ItemRendererProps<ListOptionItem>) => JSX.Element
) => {
	const { item, noIcons, needle, iconFill } = renderProps;

	if ("isDivider" in item) {
		return <Divider />;
	}

	const maybeIcon = !noIcons && item.icon && (
		<span className={scss.icon}>
			<Icon size="medium" fill={item.iconFill ?? iconFill}>
				{item.icon}
			</Icon>
		</span>
	);

	if ("isSectionHeader" in item) {
		return <DropdownSectionHeader text={item.text} beforeText={maybeIcon} />;
	}

	if (customRenderer) {
		return customRenderer({ item, noIcons, needle, iconFill });
	}

	const actionContent = (
		<div className={cn(scss.actionMainContent)}>
			<div className={cn(scss.titleContent)}>
				{maybeIcon}
				<InlineText lineHeight="multi-line" tone={item.disabled ? "subtle" : "neutralDark"}>
					<TextHighlight value={item.text} needle={needle} caseSensitive={false} />
				</InlineText>
			</div>
			<div>
				{item.description && (
					<InlineText size="xSmall" tone="subtle" lineHeight="multi-line">
						{item.description}
					</InlineText>
				)}
			</div>
		</div>
	);

	const renderAction = () => {
		if ("href" in item) {
			return (
				<a
					className={cn(
						scss.action,
						scss.link,
						item.selected && scss.selected,
						item.disabled && scss.disabled
					)}
					data-observe-key={item["data-observe-key"]}
					href={item.href}
					onClick={(e) => e.preventDefault()}
					rel={f2u(item.openNew && "noopener")}
					tabIndex={-1}
					target={f2u(item.openNew && "_blank")}
				>
					{actionContent}
				</a>
			);
		}

		if ("onClick" in item) {
			return (
				<button
					className={cn(
						scss.action,
						scss.button,
						item.selected && scss.selected,
						item.disabled && scss.disabled
					)}
					data-observe-key={item["data-observe-key"]}
					disabled={item.disabled}
					tabIndex={-1}
					type="button"
				>
					{actionContent}
				</button>
			);
		}
	};

	return (
		<>
			{item.tooltip ? (
				<Tooltip
					variant={{ type: "interactive" }}
					content={item.tooltip}
					className={cn(scss.tooltip)}
				>
					{renderAction()}
				</Tooltip>
			) : (
				renderAction()
			)}
		</>
	);
};

assignKnobName(InternalActionList);
InternalActionList.displayName = "ActionList";

export function isItemDivider(item: ActionListItem): boolean {
	return typeof item === "object" && "isDivider" in item;
}

export const divider = { isDivider: true } as const;

export const sectionHeader = (text: string, icon?: JSX.Element) =>
	({ text, icon, isSectionHeader: true } as const);

export function isListOptionItem(x: ActionListItem): x is ListOptionItem {
	return !("isDivider" in x || "isSectionHeader" in x);
}

export function stringifyItem(item: ActionListItem): string {
	if (isListOptionItem(item)) return item.text;
	return "";
}

export const ActionList = Object.assign(InternalActionList, { divider, sectionHeader });
